import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/home/Home.vue";
import nProgress from "nprogress";
import 'nprogress/nprogress.css'

let menu = JSON.parse(localStorage.getItem('menu'))
import {getTypeList} from '../utils/methodUtil.js';

let url = '/login'
if (menu != null) {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].children != null && menu[i].children.length > 0) {
            url = `/${menu[i].children[0].index}`
            break
        } else {
            url = `/${menu[i].index}`
            break
        }
    }
}
const routes = [
        {
            path: '/',
            redirect: url
        }, {
            path: "/",
            name: "Home",
            component: Home,
            children: [
                {
                    path: "/homeDetail",
                    name: "homeDetail",
                    meta: {
                        title: '首页'
                    },
                    component: () => import ("../views/home/HomeDetail.vue")
                },
                {
                    path: "/coach",
                    name: "Coach",
                    meta: {
                        title: '教练管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/coach/Coach")
                },
                {
                    path: "/user",
                    name: "user",
                    meta: {
                        title: '用户管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/coach/User")
                },
                {
                    path: "/students",
                    name: "students",
                    meta: {
                        title: '学生管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/student/studentList")
                },
                {
                    path: "/orders",
                    name: "orders",
                    meta: {
                        title: '订单统计',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/statistical/Orders")
                },
                {
                    path: "/courses",
                    name: "courses",
                    meta: {
                        title: '课时统计',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/statistical/Courses")
                }, {
                    path: "/coursesRecord",
                    name: "CoursesRecord",
                    meta: {
                        title: '课时记录',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/courseRecord/CoursesRecord")
                },
                {
                    path: "/CourseConfirm",
                    name: "CourseConfirm",
                    meta: {
                        title: '课时确认',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/courseRecord/CourseConfirm")
                },
                {
                    path: "/testTree",
                    name: "TestTree",
                    meta: {
                        title: 'testTree',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/thread/TestTree")
                },
                {
                    path: "/campus",
                    name: "campus",
                    meta: {
                        title: '校区管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Campus")
                },
                {
                    path: "/banner",
                    name: "banner",
                    meta: {
                        title: '轮播图管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Banner"),
                },
                {
                    path: "/trainingRecord",
                    name: "trainingRecord",
                    meta: {
                        title: '教学培训',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/TrainingRecord"),
                }, {
                    path: "/region",
                    name: "region",
                    meta: {
                        title: '城市管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Region"),
                },
                {
                    path: "/card",
                    name: "CardType",
                    meta: {
                        title: '卡片管理',
                        keepAlive: true
                    },
                    component: () => import ("../views/manage/CardType")
                },
                {
                    path: "/card2",
                    name: "Card",
                    meta: {
                        title: '卡片管理'
                    },
                    component: () => import ("../views/card/Card")
                }, {
                    path: "/calculator",
                    name: "calculator",
                    meta: {
                        title: '中考计算器'
                    }, component: () => import (
                        "../views/manage/Calculator")
                }, {
                    path: "/competeEnterprise",
                    name: "competeEnterprise",
                    meta: {
                        title: '竞品企业'
                    }, component: () => import ("../views/manage/CompeteEnterprise")
                },
                {
                    path: "/OrdersManage",
                    name: "OrdersManage",
                    meta: {
                        title: '订单管理',
                        keepAlive: true
                    },
                    component: () => import ("../views/order/OrdersManage")
                },
                {
                    path: "/follow",
                    name: "follow",
                    meta: {
                        title: '回访管理',
                        keepAlive: true
                    },
                    component: () => import("../views/order/Follow")
                },
                 {
                    path: "/tradingRecord",
                    name: "tradingRecord",
                    meta: {
                        title: '缴费记录',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/order/TradingRecord")
                },

                {
                    path: "/qc",
                    name: "Equipment",
                    meta: {
                        title: '器材管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Equipment")
                },
                {
                    path: "/qcLq",
                    name: "EquipmentLq",
                    meta: {
                        title: '器材领取记录',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/EquipmentLq")
                },
                {
                    path: "/equipmentMovements",
                    name: "equipmentMovements",
                    meta: {
                        title: '入库单',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/EquipmentMovements")
                },
                {
                    path: "/menus",
                    name: "menus",
                    meta: {
                        title: '菜单管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Menus")
                },
                {
                    path: "/roles",
                    name: "roles",
                    meta: {
                        title: '角色管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/manage/Roles")
                },
                {
                    path: "/transcript",
                    name: "transcript",
                    meta: {
                        title: '体测学员管理'
                    },
                    component: () => import (
                        "../views/transcript/Transcript")
                },
                {
                    path: "/sysUser",
                    name: "sysUser",
                    meta: {
                        title: '用户管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/system/SYSUser")
                },
                {
                    path: "/SYSMenu",
                    name: "SYSMenu",
                    meta: {
                        title: '菜单管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/system/SYSMenu")
                },
                {
                    path: "/stopOrder",
                    name: "stopOrder",
                    meta: {
                        title: '停卡明细',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/order/SopOrder")
                },
                {
                    path: "/apportionOrder",
                    name: "apportionOrder",
                    meta: {
                        title: '订单分摊'
                    },
                    component: () => import (
                        "../views/order/apportion/ApportionOrder")
                },
                {
                    path: "/financeApportionOrder",
                    name: "financeApportionOrder",
                    meta: {
                        title: '财务分摊'
                    },
                    component: () => import (
                        "../views/order/FinanceApportionOrder")
                },
                {
                    path: "/citiesApportion",
                    name: "citiesApportion",
                    meta: {
                        title: '分摊'
                    },
                    component: () => import (
                        "../views/order/apportion/CitiesApportion")
                },

                {
                    path: "/zhengzhouApportion",
                    name: "zhengzhouApportion",
                    meta: {
                        title: '郑州分摊'
                    },
                    component: () => import (
                        "../views/order/apportion/ZhengzhouApportion")
                },
                {
                    path: '/bodyFinancial',
                    name: 'bodyFinancial',
                    meta: {
                        title: '贝体分摊'
                    },
                    component: () => import(
                        '../views/order/apportion/bodyFinancial'
                        )
                },
                {
                    path: "/area",
                    name: "area",
                    meta: {
                        title: '区域管理',
                        keepAlive: true
                    },
                    component: () => import (
                        "../views/system/Area")
                },
                {
                    path: "/unpaidOrder",
                    name: "unpaidOrder",
                    meta: {
                        title: '微信支付'
                    },
                    component: () => import (
                        "../views/order/UnpaidOrder")
                }, {
                    path: '/return',
                    name: 'return',
                    meta: {
                        title: '退费',
                        keepAlive: true
                    },
                    component: () => import(
                        "../views/order/return/Return"
                        )
                }, {
                    path: '/turn',
                    name: 'turn',
                    meta: {
                        title: '转卡',
                        keepAlive: true
                    },
                    component: () => import(
                        "../views/order/turn/turn"
                        )
                }, {
                    path: '/wangEditor',
                    name: 'wangEditor',
                    meta: {
                        title: '帮助中心',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/system/Wangdditor'
                        )
                }, {
                    path: '/courseSchedule',
                    name: '课程表',
                    meta: {
                        title: '课程表',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/courseSchedule/CourseSchedule'
                        )
                }, {
                    path: '/dictionaries',
                    name: '字典',
                    meta: {
                        title: '字典',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/system/dictionaries'
                        )
                }, {
                    path: '/framework',
                    name: '教练组织架构',
                    meta: {
                        title: '教练组织架构',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views//manage/Framework'
                        )
                }, {
                    path: '/video',
                    name: '视频管理',
                    meta: {
                        title: '视频管理',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/video/Video'
                        )
                }, {
                    path: '/wuhanApportion',
                    name: '武汉分摊',
                    meta: {
                        title: '武汉分摊'
                    },
                    component: () => import(
                        '../views/order/apportion/wuhanApportion'
                        )
                },
                {
                    path: '/homeDetail',
                    name: '首页',
                    meta: {
                        title: '首页'
                    },
                    component: () => import(
                        '../views/home/HomeDetail'
                        )
                },
                {
                    path: '/ceshi',
                    name: '组织架构-部门',
                    meta: {
                        title: '组织架构-部门'
                    },
                    component: () => import(
                        '../views/ceshi/ceshi1'
                        )
                },
                {
                    path: '/crmLine',
                    name: '线索列表',
                    meta: {
                        title: '线索列表'
                    },
                    component: () => import(
                        '../views/attract/CrmLine'
                        )
                },
                {
                    path: '/attract',
                    name: '客户管理',
                    meta: {
                        title: '客户管理'
                    },
                    component: () => import(
                        '../views/attract/attract'
                        )
                },
                {
                    path: '/attractContact',
                    name: '联系记录',
                    meta: {
                        title: '联系记录'
                    },
                    component: () => import(
                        '../views/attract/AttractContact'
                        )
                },
                {
                    path: '/attractOrder',
                    name: '签单记录',
                    meta: {
                        title: '签单记录'
                    },
                    component: () => import(
                        '../views/attract/AttractOrder'
                        )
                },
                {
                    path: '/attractLink',
                    name: '环节列表',
                    meta: {
                        title: '环节列表'
                    },
                    component: () => import(
                        '../views/attract/AttractLink'
                        )
                },
                {
                    path: '/groundPush',
                    name: '地推统计',
                    meta: {
                        title: '地推统计'
                    },
                    component: () => import(
                        '../views/statistical/Groundpush'
                        )
                },
                {
                    path: '/roster',
                    name: '花名册',
                    meta: {
                        title: '花名册'
                    },
                    component: () => import(
                        '../views/coach/roster/Roster'
                        )
                },
                {
                    path: '/questionnaire',
                    name: '日数据管理汇总',
                    meta: {
                        title: '日数据管理汇总'
                    },
                    component: () => import(
                        '../views/system/Questionnaire'
                        )
                },
                {
                    path: '/performanceTarget',
                    name: '业绩目标',
                    meta: {
                        title: '业绩目标'
                    },
                    component: () => import(
                        '../views/monthTarget/PerformanceTarget'
                        )
                },
                {
                    path: '/yearTotalTarget',
                    name: '年度总目标',
                    meta: {
                        title: '年度总目标'
                    },
                    component: () => import(
                        '../views/monthTarget/YearTotalTarget'
                        )
                },
                {
                    path: '/targetStatistics',
                    name: '业绩统计',
                    meta: {
                        title: '业绩统计'
                    },
                    component: () => import(
                        '../views/monthTarget/TargetStatistics'
                        )
                },
                {
                    path: '/overallLog',
                    name: '全局日志记录',
                    meta: {
                        title: '全局日志记录'
                    },
                    component: () => import(
                        '../views/log/OverallLog'
                        )
                },
                {
                    path: '/products',
                    name: '商品管理',
                    meta: {
                        title: '商品管理'
                    },
                    component: () => import(
                        '../views/mall/Products'
                        )
                },
                {
                    path: '/integralDetail',
                    name: '积分明细',
                    meta: {
                        title: '积分明细'
                    },
                    component: () => import(
                        '../views/mall/IntegralDetail'
                        )
                },
                {
                    path: '/prizeDetail',
                    name: '奖品明细',
                    meta: {
                        title: '奖品明细'
                    },
                    component: () => import(
                        '../views/mall/PrizeDetail'
                        )
                },
                {
                    path: '/gainIntegralRule',
                    name: '积分规则',
                    meta: {
                        title: '积分规则'
                    },
                    component: () => import(
                        '../views/mall/GainIntegralRule'
                        )
                },
                {
                    path: '/appManage',
                    name: 'App版本管理',
                    meta: {
                        title: 'App版本管理'
                    },
                    component: () => import(
                        '../views/manage/AppManage'
                        )
                },
                {
                    path: '/threadList',
                    name: 'threadList',
                    meta: {
                        title: '线索管理',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/thread/ThreadList'
                        )
                },
                {
                    path: '/newThreadList',
                    name: 'newThreadList',
                    meta: {
                        title: '线索管理',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/thread/NewThreadList'
                        )
                },
                {
                    path: '/courseTarget',
                    name: 'courseTarget',
                    meta: {
                        title: '课消目标',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/monthTarget/courseTarget'
                        )
                },
                {
                    path: '/probationList',
                    name: 'probationList',
                    meta: {
                        title: '试课列表',
                        keepAlive: true
                    },
                    component: () => import(
                        '../views/order/ProbationList'
                        )
                },
                {
                    path: '/physicalTest',
                    name: 'physicalTest',
                    meta: {
                        title: '焱火体测',
                    },
                    component: () => import(
                        '../views/statistical/physicalTest'
                        )
                },
                {
                    path: '/free',
                    name: 'free',
                    meta: {
                        title: '零元卡',
                    },
                    component: () => import(
                        '../views/applyRecord/free/Free'
                        )
                },
                {
                    path: '/dingHistorical',
                    name: 'dingHistorical',
                    meta: {
                        title: '钉钉历史数据',
                    },
                    component: () => import(
                        '../views/dingHistorical/DingHistorical'
                        )
                },
                {
                    path: '/pkGame',
                    name: 'pkGame',
                    meta: {
                        title: 'PK',
                    },
                    component: () => import(
                        '../views/pk/PKGame'
                        )
                },
                {
                    path: '/policyPhoto',
                    name: 'policyPhoto',
                    meta: {
                        title: '政策、学员图片',
                    },
                    component: () => import(
                        '../views/manage/policyPhoto'
                        )
                },
                {
                    path: '/submitOther',
                    name: 'submitOther',
                    meta: {
                        title: '职能提报',
                    },
                    component: () => import(
                        '../views/order/SubmitOther'
                        )
                },
                {
                    path: '/activityList',
                    name: '活动列表',
                    meta: {
                        title: '活动列表'
                    },
                    component: () => import(
                        '../views/activity/activityList'
                        )
                },
            ]
        }, {
            path: "/login",
            name: "Login",
            meta: {
                title: '登录'
            },
            component: () => import (
                "../views/NewLogin")
        }
    ]
;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

/* 挂载路由导航守卫 */
router.beforeEach((to, from, next) => {
        if (JSON.parse(localStorage.getItem('menu'))) {
            getTypeList(JSON.parse(localStorage.getItem('menu')), to.path);
        }
        document.title = `${to.meta.title} | 焱火体育`;
        if (to.path === '/login') {
            nProgress.start()
            next()
        }
        let tokenStr = window.localStorage.getItem('token')
        /*  本地是否存有token */
        if (tokenStr !== null && tokenStr !== 'undefined' && menu !== null) {
            getTypeList(menu, to.path);
            next()
        } else {
            next('/login')
        }

    },
    router.afterEach(() => {
        nProgress.done();
    })
);


export default router;
