import request from '../utils/request';

/**
 * 获取教练列表
 * @param query
 * @returns {AxiosPromise}
 */
export const pageList = (query) => {
    return request({
        url: '/sys/coach/pageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};







/**
 * 修改学员信息
 */
export const updateOrderInfo = (list) => {
    return request({
        url: '/sys/orders/updateOrderInfo',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//修改证书状态
export const certificateState = (id,state) => {
    return request({
        url: '/sys/coach/updateCoachLabelState',
        method: 'get',
        params: {id,state}
    });
};




/**
 * 学员转移功能，根据城市查询教练
 * @param query
 * @returns {AxiosPromise}
 */
export const transferSelectCoach = (keyword) => {
    return request({
        url: '/sys/coach/listCoach',
        method: 'get',
        params: {keyword}
    });
};

/**
 * 根据姓名或者手机号查询教练
 * @param query
 * @returns {AxiosPromise}
 */
export const coachByNameOrPhone = (name,phone) => {
    return request({
        url: '/sys/coach/coachByNameOrPhone',
        method: 'get',
        params:{name,phone}
    });
};

/**
 * 修改教练状态
 * @param coachId
 * @param state
 * @returns {AxiosPromise}
 */
export const editSate = (coachId,state) => {
    return request({
        url: '/sys/coach/editState',
        method: 'get',
        params:{
            coachId,
            state
        }
    });
};
/**
 * 修改教练信息
 * @param coach
 * @returns {AxiosPromise}
 */
export const edit = (coach) => {
    return request({
        url: '/sys/coach/updateCoachRoster',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(coach),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 新增教练信息
 * @param coach
 * @returns {AxiosPromise}
 */
export const creationCoach = (coach) => {
    return request({
        url: '/sys/coach/coachAdd',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(coach),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};


/**
 * 删除教练
 * @param coachId
 * @returns {AxiosPromise}
 */
export const del = (coachId) => {
    return request({
        url: '/sys/coach/delete',
        method: 'get',
        params:{
            coachId
        }
    });
};

/**
 * 角色列表
 * @returns {AxiosPromise}
 */
export const roles = () => {
    return request({
        url: '/sys/coach/roles',
        method: 'get',
    });
};

/**
 * 角色列表
 * @returns {AxiosPromise}
 */
export const selectCoachById = (regionId) => {
    return request({
        url: '/sys/coach/selectCoachById',
        method: 'get',
        params:{
            regionId
        }
    });
};

/**
 * 薪资通知
 * @param query
 * @returns {AxiosPromise}
 */
export const sendEmail = (file) => {
    return request({
        url: '/sys/email/sendEmail',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};
/**
 * 查询所有教练
 */
export const listCoachAll = (flag) => {
    return request({
        url: '/sys/coach/listCoachAll',
        method: 'get',
        params:{flag}
    });
};

//修改教练是否开启师资背景
export const changeFaculty = (id,visible) => {
    return request({
        url: '/sys/coach/updateCoachIsVisible',
        method: 'get',
        params:{id,visible}
    });
};

//查询证书
export const getcertificate = (coachId,type) => {
    return request({
        url: '/sys/coach/listCoachLabel',
        method: 'get',
        params:{coachId,type}
    });
};

//添加证书
export const addcertificate = (query) => {
    return request({
        url: '/sys/coach/addCoachLabel',///api/sys/coach/addCoachLabel
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//添加或修改师资背景
export const getOramend = (query) => {
    return request({
        url: '/sys/coach/addOrUpdate',
        method: 'POST',
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//教练导出
export function exportCoach(query) {
    return request({
        url: '/sys/coach/exportCoach',
        method: 'POST',
        responseType: 'arraybuffer',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
//导出花名册
export function exportCoachRosterExcel(query) {
    return request({
        url: '/sys/coach/exportCoachRosterExcel',
        method: 'POST',
        responseType: 'arraybuffer',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
//生成工号
export function createWorkNumber() {
    return request({
        url: '/sys/coach/createWorkNumber',
        method: 'GET',
    })
}

//根据教练id查询合同记录 
export function selectCoachContractByCoachId(coachId) {
    return request({
        url: '/sys/coach/selectCoachContractByCoachId',
        method: 'GET',
        params:{
            coachId
        }
    })
}
