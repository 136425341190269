<template>
  <div class="about">
    <v-header />
    <v-sidebar />
    <div :class="collapse==true?'content-box1':'content-box'">
      <v-tags></v-tags>
      <ul class="infinite-list content" style="overflow: auto;">
        <div class="container" style="overflow-x:auto">
          <router-view v-slot="{ Component }">
            <transition name="move" mode="out-in">
              <keep-alive :include="tagsList">
                <component :is="Component" />
              </keep-alive>
            </transition>
          </router-view>
        </div>
      </ul>
    </div>
<!--    合同到期提醒 弹框-->
    <el-dialog title="合同到期提醒" v-model="contractDeadlineDialog" width="1300px">
      <el-button type="primary" @click="selectContractDeadline">刷新列表</el-button>
      <el-table
          :data="contractDeadline"
          height="530px"
          :row-style="{height: '35px'}"
          border
          style="width: 100%"
      >
        <el-table-column label="id" width="150" align="center" show-overflow-tooltip prop="coachid"/>
        <el-table-column label="工号" width="150" align="center" show-overflow-tooltip prop="workNumber"/>
        <el-table-column label="姓名" width="150" align="center" show-overflow-tooltip prop="coachname"/>
        <el-table-column label="个人电话" width="150" align="center" show-overflow-tooltip prop="coachphone"/>
        <el-table-column label="签署主体" align="center" show-overflow-tooltip prop="signContractFirstPartyName"/>
        <el-table-column label="部门" align="center" show-overflow-tooltip prop="deptName"/>
        <el-table-column label="操作" width="100" fixed="right" align="center">
          <template #default="scope">
            <el-button @click="contractExtension(scope.row)" style="width: 14px;" size="small" type="text">续签合同</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <contract-extension @refreshList="selectContractDeadline" ref="contract" :item="detailItem"/>
  </div>
</template>
<script>
import vHeader from "../../components/Header";
import vSidebar from "../../components/Sidebar";
import vTags from "../../components/Tags.vue";
import contractExtension from "../coach/roster/components/ContractExtension";
import {contractExpireCoachList} from "@/api/coachRoster";
export default {
  components: {
    contractExtension,
    vHeader,
    vSidebar,
    vTags
  },
  data() {
    return {
      detailItem:null,
      contractDeadline:[],
      contractDeadlineDialog:false,
    }
  },
  methods: {
    /**
     * 调用子组件方法打开弹出层
     */
    contractExtension(item){
      this.detailItem = item;
      this.detailItem.coachId = item.coachid;
      this.$refs.contract.openDialog();
    },
    /**
     * 查询合同到期列表
     */
    selectContractDeadline(){
      contractExpireCoachList().then(val =>{
        if(val.code == 200 &&  val.data &&  val.data.length>0){
          this.contractDeadline = val.data;
          this.contractDeadlineDialog = true;
        }
      })
    },
  },
  created(){
    this.selectContractDeadline();

  },

  computed: {

    tagsList() {
      return this.$store.state.tagsList.map(item => item.name);
    },
    collapse() {
      return this.$store.state.collapse;
    }
  }
};
</script>
